.datagrid-even {
    background-color: #e0e0e0;
}

.colored-grid-cell {
    padding: 5px;
    border-radius: 5px;
    text-align: center;
    display: flex;
    justify-content: center;
}

/* Wrap text in column header */
.wrap-header-text .MuiDataGrid-columnHeaderTitle {
    white-space: normal !important;
    text-overflow: clip !important;
    overflow: visible !important;
    line-height: 1.2; /* Adjust line height as needed */
    display: block; /* Allow block-level wrapping */
    word-wrap: break-word;
    font-weight: bold;
}

.custom-toolbar {
    display: flex;
    justify-content: space-between;
}

.right-toolbar-content {
    margin-left: auto; /* Additional spacing if required */
}

.btn-dark-custom {
    color: rgba(255, 255, 255, 0.5);
}

.table-condensed {
    font-size: small;
}

.table-condensed td {
    padding: 0.3rem;
}

.table-condensed th {
    padding: 0.3rem;
}

.pageContainer {
    position: relative;
    min-height: 100vh;
}

.contentWrap {
    padding-bottom: 2.5rem;
}

.footerDiv {
    padding-top: 20px;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2.5rem;
}

.Spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.historyTableDiv {
    padding: 0% 5%;
}

.loginForm {
    width: 50%;
    margin-top: 3%;
}

/* Risk Detail Form CSS */

#riskDetailForm {
    padding-top: 20px;
}

div.sticky {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    background-color: lightskyblue;
    z-index: 2;
}

form input {
    width: 100%;
}

form textarea {
    width: 100%;
}

form label {
    font-weight: bold;
}

#form-paragraph {
    font-style: italic;
}

.btn-group > .btn:not(:last-child) {
    margin-right: 10px;
}

.btn-group-ind > .btn:not(:last-child) {
    margin-right: 10px;
}

.btn {
    border-radius: 12px;
    border: none;
}

.form-heading {
    text-align: center;
    background-color: #179ed1;
    /* color: whitesmoke; */
    padding: 8px;
    margin: 0px;
}

.form-subheading {
    text-align: center;
    background-color: #7fd1f1;
    /* color: whitesmoke; */
    padding: 4px;
}

.navbar {
    border-style: none;
}

.table td {
    border-top: none;
    border-bottom: none;
}

.table thead th {
    border-top: none;
    border-bottom: none;
}

.hoverTable tr:hover {
    background-color: #eaf8fd;
}

.hoverTable {
    width: 100%;
}

.ratingBox {
    width: 100%;
    text-align: center;
    margin-top: -16px;
    height: 37px;
    padding-top: 5px;
}

.modal-dialog {
    max-width: 1000px;
}

.instructCell {
    font-weight: bold;
}

.instructHead {
    color: whitesmoke;
}

.more-info-button {
    margin-top: -10px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    display: block;
    opacity: 0.2;
}

.centImg {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
}
